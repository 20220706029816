.media-image-editor {
  position: relative;
  flex: 1;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;

  .cropper-container {
    position: relative;
    width: 800px;
    height: 600px !important;
  }

  //
  //canvas {
  //  max-width: 780px;
  //  max-height: 590px;
  //}

  .actions {
    position: absolute;
    top: 1rem;
    left: 1rem;

    display: flex;
    gap: 10px;

    .group {
      display: flex;
      gap: 1px;

      button:first-child {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }

      button:last-child {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }

    }

    button {
      cursor: pointer;
      border: none;
      background: #00b2ff;
      color: #fff;
      padding: 5px;
      width: 40px;
      height: 40px;

      &:hover {
        background: #29beff;
      }

      &.active {
        background: #0285be;
      }

      &.media-edit-apply {
        width: auto;
        background: orange;
      }
    }

  }

  .rotate-slider {
    position: absolute;
    bottom: 20px;
    width: 300px;
    background: black;
    color: #fff;
    padding: 6px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    input {
      -webkit-appearance: none;
      width: 100%;
      height: 15px;
      border-radius: 5px;
      background: #d3d3d3;
      outline: none;
      opacity: 1;
      -webkit-transition: .2s;
      transition: opacity .2s;
    }

    input::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background: #00b2ff;
      cursor: pointer;
    }

    input::-moz-range-thumb {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background: #00b2ff;
      cursor: pointer;
    }
  }

  //
  //.media-edit-apply {
  //  position: absolute;
  //  top: 8px;
  //  right: 4px;
  //
  //  color: #fff;
  //  padding: 11px 12px;
  //  border-radius: 4px;
  //  cursor: pointer;
  //
  //  &:hover {
  //
  //  }
  //}
}
