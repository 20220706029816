.notification {
  position: fixed;
  top: 50px;
  right: 50px;
  padding: 1rem 2rem;
  border-radius: 12px;

  color: #fff;
  background: #81c981;
  font-size: 2rem;

  z-index: 9999999999999;

  &.warning {
    background: orange;
  }
  &.error {
    background: red;
  }
}
