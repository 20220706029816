.category-delete-modal {
  background: #fff;
  padding: 2rem;
  border-radius: 1rem;
  margin: auto;
  z-index: 10;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 600px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h1 {
    font-size: 2rem;
    margin: 0;
  }

  .actions {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    justify-content: flex-end;

    button {
      padding: 1rem 2rem;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      color: #fff;
      &:hover {
        opacity: 0.7;
      }
    }

  }
}
