.login-popup {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  overflow: hidden;

  max-width: 400px;
  height: max-content;
  z-index: 10;

  background: #fff;
  border-radius: 8px;
  padding: 15px;

  .error {
    color: #f00;
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  .login-btn {
    display: block;
    border-radius: 4px;
    background: #5b66ff;
    color: #fff;
    padding: 8px;
    width: 100%;
    cursor: pointer;
    &:hover {
      opacity: 0.9;
    }
  }

  .loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #ffffffcc;
    z-index: 20;
    display: grid;
    place-items: center;
  }
}
