html, body, #root {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, sans-serif;
}

button {
  padding: 0;
  border: none;
  background: none;
  font-size: 1rem;
}

.form-row {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;


  label {
    margin-bottom: 0.25rem;
  }

  .form-control {
    font-family: Arial, sans-serif;
    flex: 1;
    padding: 8px;
    font-size: 1rem;
  }
}

.gallery {
  display: flex;
  flex-direction: row;
  height: 100%;

  .sidebar {
    @media (max-width: 768px) {
      //display: none;
      width: 0px;
      flex: none;

      transition: width ease-in-out 500ms;

      &.open {
        width: auto;
      }
    }


    .logo {
      padding: 0 0.5rem;

      display: flex;
      flex-direction: row;
      gap: 10px;

      .back {
        //border: 2px #000 solid;
        //border-radius: 50%;
        width: 42.5px;
        height: 42.5px;
        box-sizing: border-box;
        display: grid;
        place-items: center;

        .arrow {
          border: solid black;
          border-width: 0 2px 2px 0;
          display: inline-block;
          padding: 4px;
          margin-right: -5px;
        }

        .left {
          transform: rotate(135deg);
          -webkit-transform: rotate(135deg);
        }
      }

      .title {
        font-size: 0.95rem;
        line-height: 1.5rem;
        color: #000;
        text-transform: uppercase;
        text-decoration: none;
      }

      font-size: 1rem;
      margin: 0.5rem 0;
    }


    .sidebar-switcher {
      display: flex;
      flex-direction: row;
      margin-bottom: 8px;
      padding: 0 0.5rem;
      border-radius: 4px;
      overflow: hidden;

      .sidebar-switcher-item {
        flex: 1;
        text-align: center;
        padding: 6px 4px;

        cursor: pointer;
        background: #000;
        color: #fff;

        &:hover {
          opacity: 0.7;
        }

        &.active {
          background: #fff;
          color: #000;
        }
      }
    }

    .filter {
      padding: 0.5rem 0;
      display: flex;

      input {
        font-size: 1rem;
        padding: 0.5rem;
        flex: 1;
        display: block;
        border: 1px #ccc solid;
        border-radius: 4px;
      }
    }


    background: #ccc;
    max-width: 300px;
    flex: 1;
    overflow-y: scroll;

    .category-list {
      padding: 0 0.5rem;
    }

    .category-item-list {
      & .category-item-list {
        padding-left: 1.5rem;
        position: relative;

        &:before {
          content: "";
          display: block;
          position: absolute;
          background: #000;
          width: 1px;
          height: calc(100% - 1rem);
          left: 1rem;
        }

        .category-item {
          &:before {
            content: "";
            display: block;
            position: absolute;
            height: 1px;
            background: #000;
            width: 0.5rem;
            top: 1.125rem;
            left: -0.5rem;
          }
        }
      }


      &.expanded {
        height: auto;


        //padding-bottom: 0.75rem;
      }

      &.collapsed {
        height: 0;
        overflow: hidden;
      }

      & .category-create {
        display: none;
      }

      & .category-title:hover .category-create {
        display: block;
      }
    }

    .category-item {
      position: relative;


      //padding-top: 0.5rem;

      .category-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 35px;
        flex: 1;
        padding-left: 0.5rem;
        padding-right: 2px;
        border-radius: 4px;
        //margin-bottom: 0.2rem;
        align-items: center;
        box-sizing: border-box;

        span::first-letter {
          text-transform: capitalize;
        }

        .category-create {
          max-width: 50px;
        }

        &.drag-over {
          border: 3px dashed #00b2ff;
        }

        .expand {
          padding-right: 0.25rem;
        }

        &.active {
          font-weight: bold;
          background: #fff;
        }

        &:hover {
          cursor: pointer;
          background: #ffffff;
        }

      }
    }

    .category-create {
      position: relative;
      min-height: 22px;
      background: #fff;
      padding: 4px 8px;
      border-radius: 4px;
      cursor: pointer;
      min-width: 100px;
      display: flex;

      button {
        position: absolute;
        cursor: pointer;
        margin: -4px 0 0 0;
        display: block;
        flex: 1;
        //width: -webkit-fill-available;
        height: 100%;


        &.expanded {
          display: block;
        }

        &.collapsed {
          display: none;
        }
      }

      input {
        display: none;
        margin: 0;
        padding: 4px;
        font-size: 1rem;
        width: -webkit-fill-available;

        &.expanded {
          display: grid;
        }

        &.collapsed {
          display: none;
        }
      }

      &:hover {
        background: #00b2ff;
        cursor: pointer;
      }
    }

  }

  .medias {

  }
}

.media-view {
  background: #eee;
  flex: 1;
  padding: 0 1rem;
  height: 100vh;
  overflow-y: scroll;
  position: relative;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 -1rem;
    background: #fff;
    padding: 0.5rem 1rem;


    @media (max-width: 768px) {
      flex-direction: column-reverse;
      gap: 10px
    }


    .title {

      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex: 1;
      padding-right: 1rem;
      align-self: stretch;

      @media (max-width: 768px) {
        margin: 1rem 0;
      }

      .text {
        flex: 1;
        overflow: hidden;
        position: relative;
        align-items: center;
        display: flex;
        @media (max-width: 768px) {
          height: 2rem;
        }

        input {
          flex: 1;
          font-size: 1.5rem;
          padding: 0 0 0 0.5rem;
          align-self: stretch;
        }

        h1 {
          position: absolute;
          white-space: nowrap;
          margin: 0;
        }
      }

      .actions {


        position: relative;
        align-items: center;

        &:before {
          content: "";
          position: absolute;
          display: block;
          top: 0;
          left: -2rem;
          background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%);
          width: 2rem;
          height: 100%;
        }

        button, a {
          background: #eee;
          border-radius: 4px;
          padding: 0 6px;
          width: 40px;
          height: 40px;
          cursor: pointer;
          text-decoration: none;
          display: flex;
          box-sizing: border-box;
          align-items: center;
          justify-content: center;
          &:hover {
            opacity: 0.7;
          }
        }
      }
    }

    .actions {
      align-self: stretch;

      .mobile-menu {
        display: none;
        min-width: 50px;
        max-width: 50px;
        height: 50px;
        padding: 0;

        @media (max-width: 768px) {
          display: block;
        }
      }

      display: flex;
      @media (max-width: 768px) {
        flex: 1;
      }
      gap: 0.25rem;

      .search {
        flex: 1;
        @media (min-width: 768px) {
          min-width: 300px;
        }
        display: flex;
        position: relative;

        input {
          border: none;
          flex: 1;
          border-radius: 4px;
          background: #eee;
          padding-left: 32px;
          font-size: 1rem;
        }
      }
    }

    .login-btn {
      background: #fff;
      border-radius: 4px;
      display: block;
      height: 50px;
      align-self: center;
      padding: 4px 12px;
      cursor: pointer;

      &.active {
        background: #008000;
        color: #fff;
      }
    }
  }
}

.media-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-auto-rows: 220px;
  grid-gap: 25px 5px;
  padding-bottom: 5rem;
  padding-top: 1rem;
  height: max-content;
  min-height: 100vh;

  .add-image {
    width: 200px;
    height: 200px;
    text-align: center;
    border: 8px #bbb solid;
    position: relative;
    cursor: pointer;
    color: #bbb;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    border-radius: 4px;

    &:hover {
      border: 8px #808080 solid;
      color: #808080;
    }

    input {
      cursor: pointer;
      opacity: 0;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
    }
  }

  .not-found {
    flex: 1;
    font-size: 2rem;
    text-align: center;
    align-self: center;
  }

  .media-container {

    &.selected {
      background: #00b2ff;
    }

    place-content: center;
    display: grid;
    position: relative;

    &.uploading {

      img {
        max-width: 200px;
        opacity: 0.2;
      }
    }

    &:hover {
      transition: transform 0.2s;
      transform: scale(1.05);
    }

    cursor: pointer;

    .media {

      //overflow: hidden;
      display: flex;
      justify-content: center;
      position: relative;


      .actions {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        flex-direction: row;
        gap: 10px;

        .alert {
          width: 30px;
          height: 30px;
          opacity: 0.7;
          //background: #ffa100;
          border-radius: 2px;
          color: #fff;
          text-align: center;
          font-size: 1rem;
          position: relative;

          &:before {
            content: "Ha duplicati"; /* here's the magic */
            position: absolute;

            /* vertically center */
            top: 0;
            transform: translateY(-100%);

            /* move to right */
            left: 0;
            margin-left: 15px; /* and add a small left margin */

            /* basic styles */
            white-space: nowrap;
            padding: 10px;
            border-radius: 10px;
            background: #000;
            color: #fff;
            text-align: center;

            display: none;
            z-index: 9999;
          }

          &:hover:before {
            display: block;
          }

          &:hover {
            opacity: 1;
          }
        }


        .delete {
          display: none;
          width: 30px;
          height: 30px;
          color: #fff;
          font-size: 1.5rem;
          border-radius: 4px;
          border: none;
          cursor: pointer;

          &:hover {
            opacity: 0.8;
          }
        }
      }

      &:hover .actions {
        .delete {
          display: block;
        }
      }

      .dragged {
        opacity: 0.5;
        scale: 1;
      }


      img {
        max-width: 200px;
        border-radius: 3px;
        max-height: 180px;
        box-shadow: 4px 3px 5px grey;
      }
    }

    .media-title {
      text-align: center;
      width: 200px;
      display: block;
      margin: 10px auto;

      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #00000077;
  z-index: 1;
}

.modal-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9999;
  width: 60px;
  height: 60px;
  cursor: pointer;
}

@mixin center-block {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.media-viewer {
  @include center-block;

  //position: relative;


  display: flex;

  background: #fff;
  max-width: 1100px;
  max-height: 600px;
  border-radius: 4px;
  //overflow: hidden;
  z-index: 10;

  .loading {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: #ffffffcc;
    z-index: 9999;
  }

  .media-viewer-nav {
    position: absolute;
    z-index: 99999;
    width: 100px;
    height: 100px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;


    &:hover {
      opacity: 0.5;
    }

    &.back {
      left: -100px;
    }

    &.forward {
      right: -100px;
    }

    @media (max-width: 768px) {
      top: unset;
      bottom: -100px;
      transform: unset;

      &.back {
        left: 0;
      }
      &.forward {
        right: 0;
      }

    }

  }

  .media-viewer-description {
    padding: 10px;
    //position: absolute;
    //bottom: 0;
    align-self: stretch;
    background: #ffffff;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    height: fit-content;
  }

  .media-container {
    min-width: 800px;
    min-height: 600px;
    max-width: 800px;
    max-height: 600px;

    @media (max-width: 768px) {
      min-width: unset;
      min-height: unset;
      max-width: unset;
      max-height: unset;
    }

    position: relative;

    background: #525252;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    .media-actions {
      position: absolute;
      top: 1rem;
      left: 1rem;

      display: flex;
      gap: 10px;
      //background: #eeeeee;

      .action {
        width: 50px;
        height: 50px;
      }

      a {
        text-decoration: none;
      }
    }

    .media-zoom {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 9999;
      background: #fff;
      width: 40px;
      height: 40px;
      border-radius: 2px;

      span {
        height: 22px;
      }
    }

    .media-wrapper {
      flex: 1;
      display: flex;
      align-items: center;
      overflow: hidden;
      padding: 1rem;
      align-self: stretch;
      justify-content: center;

      .media {
        max-width: 780px;
        max-height: 580px;
        @media (max-width: 768px) {
          max-width: unset;
          max-height: 400px;
          width: 100%;
        }
        box-shadow: 4px 3px 5px black;
      }
    }
  }

  .popup-actions {
    //position: absolute;
    //top: 5px;
    //right: 5px;
    z-index: 10;
    padding-bottom: 0.5rem;

    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;

    .edit-button {
      cursor: pointer;
      background: #00b2ff;
      color: #fff;
      padding: 6px 12px;
      border-radius: 4px;
    }
  }

  .info {
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 1rem;
    position: relative;
    flex: 1;


    ul {
      display: flex;
      flex-direction: row;
      list-style: none;
      gap: 10px;

      li {
        background: #ccc;
        padding: 4px 8px;
        border-radius: 4px;
      }

      padding: 0;
    }


    h2 {
      margin: 0;
    }
  }

  .media-duplicates {
    align-self: flex-end;

    .list {
      display: flex;
      flex-direction: row;
      gap: 10px;
      flex-wrap: wrap;
    }
  }

  .media-info {
    position: relative;
    display: flex;
    flex-direction: column;

    .media-path {
      color: #000;
      text-decoration: none;
      &:hover {
        color: #5b66ff;
        text-decoration: underline;
      }
    }

    h1 {
      font-size: 1.5rem;
      margin: 0 0 0.5rem 0;
    }

    h4 {
      font-size: 1rem;
      margin: 0.5rem 0;
    }


    .actions {
      gap: 10px;
      display: flex;
      flex-direction: column;
    }

    .ReactTags__selected {
      margin-bottom: 10px;
      line-height: 36px;
    }

    .ReactTags__tagInput {
      position: relative;
      display: flex;
    }

    .ReactTags__suggestions {
      position: absolute;
      top: 40px;
      padding: 12px 0;
      background: #ccc;
      border-radius: 4px;
      overflow: hidden;
      width: 100%;


      ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        padding: 0;
        margin: 0;

        li {
          padding: 6px 8px;

          &.ReactTags__activeSuggestion {
            background: #0f0;
            cursor: pointer;
          }
        }
      }
    }

    .ReactTags__tag {
      background: #aaa;
      padding: 6px;
      border-radius: 4px;
      margin-right: 8px;

      .ReactTags__remove {
        margin-left: 4px;
        cursor: pointer;

        &:hover {
          color: red;
        }
      }
    }
  }
}

.tag-list {

  padding: 0;
  display: flex;
  flex-direction: column;


  a {
    padding: 6px 8px;
    color: #000;
    text-decoration: none;

    &.active {
      font-weight: bold;
      background: #fff;
    }

    &:hover {
      background: #fff;
    }
  }
}

.btn {
  padding: 1rem 2rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: #fff;

  &:hover {
    opacity: 0.7;
  }
}

.btn-block {
  flex: 1;
}

.btn-mini {
  padding: 0.5rem 1rem;
}

.btn-success {
  background: #81c981 !important;
}

.btn-edit {
  background: #00b2ff !important;
}

.btn-danger {
  background: #e78686 !important;
}

.btn-auto {
  width: auto !important;
}
